import { TextField, useTheme } from "@mui/material";
import { EducationInputLabel } from "./common/EducationInputLabel";
import FieldContainer from "./common/FieldContainer";
import { fieldStyles } from "./common/fieldStyles";

export default function Text({
  id,
  label,
  value,
  onChange,
  type = "string",
  placeholder = "",
  disabled = false,
  required = false,
  fieldInfo,
}) {
  const theme = useTheme();
  const baseStyle = fieldStyles(theme);
  return (
    <FieldContainer>
      <EducationInputLabel
        fieldInfo={fieldInfo}
        label={label}
        id={id}
        required={required}
      />
      <TextField
        aria-labelledby={id}
        variant="outlined"
        sx={baseStyle.fieldContainer}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        InputProps={{
          style: {
            borderRadius: "0.6rem", // cant use fieldStyles here because TextField has its own styles
          },
        }}
      />
    </FieldContainer>
  );
}
