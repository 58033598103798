import FilledButton from "./FilledButton";
import { Typography } from "@mui/material";

export default function FilledTextButton({
  text,
  onClick,
  disabled = false,
  customStyles,
}) {
  const style = styles();

  return (
    <FilledButton
      onClick={onClick}
      disabled={disabled}
      extraStyles={{ width: "fit-content" }}
    >
      <Typography
        color={disabled ? "grey" : "white"}
        sx={{ ...style.text, ...customStyles }}
      >
        {text}
      </Typography>
    </FilledButton>
  );
}

const styles = () => ({
  text: {
    fontSize: "14px",
    padding: "0.5rem",
  },
});
