import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function InfoIcon({ details, iconStyle=undefined }) {
  iconStyle = iconStyle ||  {};
  return (
    <Tooltip title={details} arrow placement={"right"}>
      <IconButton sx={{ cursor: "default", ...iconStyle }}>
        <InfoOutlinedIcon/>
      </IconButton>
    </Tooltip>
  );
}

export default InfoIcon;
