import { Box, Typography } from "@mui/material";
import successToast from "../toasts/SuccessToast";
import errorToast from "../toasts/ErrorToast";
import { useEffect, useState } from "react";
import Feedback from "../../app/Feedback";
import TextArea from "../fields/TextArea";
import { useApplication } from "../../providers/applicationProvider";
import FilledTextButton from "../buttons/FilledTextButton";

function ContentPlanningFeedbackForm({
  contentPlanningId,
  wasFeedbackUseful,
  onFeedbackSubmitted,
  isFeedbackSubmitted,
}) {
  const application = useApplication();

  const [isSavingFeedback, setSavingFeedback] = useState(false);
  const [feedbackDetail, setFeedbackDetail] = useState();

  const style = styles();

  const USEFUL_FEEDBACK = "useful";
  const UNUSEFUL_FEEDBACK = "not_useful";

  useEffect(() => {
    setSavingFeedback(false);
    setFeedbackDetail(undefined);
  }, [contentPlanningId]);

  const saveFeedback = () => {
    setSavingFeedback(true);
    const feedbackRate = wasFeedbackUseful
      ? USEFUL_FEEDBACK
      : UNUSEFUL_FEEDBACK;
    const feedback = new Feedback(feedbackRate, feedbackDetail);
    application
      .apiClient()
      .saveContentPlanningFeedback(feedback, contentPlanningId)
      .then(() => {
        successToast("Feedback enviado.");
        onFeedbackSubmitted();
      })
      .catch(() => {
        errorToast("Ocurrió un problema al enviar el feedback.");
      })
      .finally(() => {
        setSavingFeedback(false);
      });
  };

  const updateFeedbackDetail = (value) => {
    setFeedbackDetail(value);
  };

  const isSubmitButtonEnabled = !isFeedbackSubmitted;

  const renderFeedbackForm = () => {
    return (
      <Box sx={style.mainContainer} component="form">
        <Box sx={style.headerContainer}>
          <Typography variant="h6" sx={style.title}>
            {wasFeedbackUseful
              ? "Nos alegra que te gustara! 🎉"
              : "Lamentamos que no te haya gustado. 😔"}
          </Typography>
          <Typography variant="body1">
            Si tenés un minuto, nos gustaría saber por qué.
          </Typography>
        </Box>

        <TextArea
          id="feedback"
          value={feedbackDetail}
          onChange={updateFeedbackDetail}
          placeholder="Observaciones o comentarios."
          disabled={isFeedbackSubmitted}
        />

        <FilledTextButton
          onClick={saveFeedback}
          disabled={!isSubmitButtonEnabled}
          text={!isSavingFeedback ? "Enviar" : "Enviando..."}
        />
      </Box>
    );
  };

  if (isFeedbackSubmitted) {
    return (
      <Box sx={style.feedbackSentContainer}>
        <Typography variant="h6" sx={style.title}>
          😊
        </Typography>
        <Typography variant="h6" sx={style.title}>
          ¡Gracias por tu feedback!
        </Typography>
        <Typography variant="body1" sx={style.subtitle}>
          Tu opinión es importante para nosotros.
        </Typography>
      </Box>
    );
  } else {
    return renderFeedbackForm();
  }
}

export default ContentPlanningFeedbackForm;

const styles = () => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  title: {
    fontWeight: "bold",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  feedbackSentContainer: {
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
