import Button from "@mui/material/Button";

export default function OutlinedButton({
  children,
  onClick,
  disabled = false,
  extraStyles = undefined,
}) {
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      sx={extraStyles || {}}
    >
      {children}
    </Button>
  );
}
