import { Typography } from "@mui/material";
import React from "react";

export default function ContentPlanningVersionTag({ version }) {
  const style = styles();
  return (
    <Typography variant="body1" sx={style.tagVersionContainer}>
      v <b>{version}</b>
    </Typography>
  );
}

const styles = () => ({
  tagVersionContainer: {
    background: " #5555f4",
    color: "white",
    padding: "2px 8px",
    borderRadius: "5px",
  },
});
