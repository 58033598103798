export const fetchAndUpdateStateWithResult = (
  request,
  setFetchingResult,
  setResult,
  defaultResult,
) => {
  setFetchingResult(true);
  request
    .then((result) => {
      setFetchingResult(false);
      setResult(result);
    })
    .catch(() => {
      setFetchingResult(false);
      setResult(defaultResult);
    });
};

export async function copyToClipboard(textForClipboard, onSuccess, onError) {
  try {
    const permissions = await navigator.permissions.query({
      name: "clipboard-write",
    });
    if (permissions.state === "granted" || permissions.state === "prompt") {
      await navigator.clipboard.writeText(textForClipboard);
      onSuccess();
    } else {
      onError();
    }
  } catch (error) {
    onError();
  }
}

export function getUrlForContentPlanningWith(publicId) {
  const baseUrl = process.env.REACT_APP_FRONTEND_URL;
  return `${baseUrl}/public_content_planning/${publicId}`;
}
