import React from "react";
import { Box, Typography } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { observer } from 'mobx-react';

function AppVersion({ version }) {
  const style = styles();

  return (
    <Box sx={style.mainContainer}>
      <LocalOfferIcon sx={style.icon} />
      <Typography sx={style.versionText}>v{version}</Typography>
    </Box>
  );
}

export default observer(AppVersion);

const styles = () => ({
  mainContainer: {
    display: "flex",
    gap: "5px",
  },
  icon: {
    color: "#666973",
    width: "18px",
  },
  versionText: {
    color: "#666973",
    fontWeight: "bold",
  },
});
