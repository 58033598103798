import { Box } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import FilledIconButton from "./buttons/FilledIconButton";
import Text from "./fields/Text";
import FilledTextButton from "./buttons/FilledTextButton";
import TitleWithActions from "./TitleWithActions";
import { useApplication } from "../providers/applicationProvider";

function ContentPlanningEditableTitle({
  contentPlanning,
  useMarkdown = false,
  onContentPlanningChange,
}) {
  const [isEditing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(contentPlanning.title());
  const [isEditionSubmitted, setEditionSubmitted] = useState(false);

  const style = styles();
  const application = useApplication();

  const saveTitle = () => {
    setEditionSubmitted(true);
    application
      .apiClient()
      .updateContentPlanningTitle(contentPlanning.id(), editedTitle)
      .then((response) => {
        const contentPlanning = response.contentPlanning();
        onContentPlanningChange(contentPlanning);
        setEditing(false);
        setEditionSubmitted(false);
      })
      .catch(() => {
        setEditionSubmitted(false);
      });
  };

  const revertChanges = () => {
    setEditionSubmitted(true);
    setEditedTitle(contentPlanning.title());
    setEditing(false);
    setEditionSubmitted(false);
  };

  const renderEditableTitle = () => {
    return (
      <Box sx={style.editableTitle}>
        <Text value={editedTitle} onChange={setEditedTitle} />
        <FilledTextButton
          text={isEditionSubmitted ? "Guardando..." : "Guardar"}
          onClick={saveTitle}
          disabled={isEditionSubmitted}
        />
        <FilledTextButton
          text={isEditionSubmitted ? "Descartando..." : "Descartar"}
          onClick={revertChanges}
          disabled={isEditionSubmitted}
        />
      </Box>
    );
  };

  const renderTitle = () => {
    return (
      <TitleWithActions
        title={contentPlanning.title()}
        useMarkdown={useMarkdown}
      >
        <FilledIconButton
          icon={EditIcon}
          label={"Editar"}
          onClick={() => setEditing(true)}
        />
      </TitleWithActions>
    );
  };

  return isEditing ? renderEditableTitle() : renderTitle();
}

export default ContentPlanningEditableTitle;

const styles = () => ({
  editableTitle: {
    display: "flex",
    gap: 1,
    width: "100%",
    alignItems: "center",
  },
});
