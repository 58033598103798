import {useState} from "react";
import {useApplication} from "../../providers/applicationProvider";
import FramedContainer from "../framed-container/FramedContainer";
import {Alert, Box} from "@mui/material";
import Select from "../fields/Select";
import TextArea from "../fields/TextArea";
import FilledTextButton from "../buttons/FilledTextButton";
import YearlyContentPlanningParameters from "../../app/YearlyContentPlanningParameters";

// TODO: Remove repeated code. This is very similar to ContentPlanningForm.
export default function YearlyContentPlanningForm({onYearlyContentPlanningChange}) {
    const regionOptions = ["CABA"];

    const gradeOptions = [
        "Segundo grado",
    ];

    const subjectOptions = ["Matemática", "Lengua"];

    const [isGeneratingContentPlanning, setGeneratingContentPlanning] =
        useState(false);
    const [subject, setSubject] = useState("");
    const [grade, setGrade] = useState(gradeOptions[0]);
    const [region, setRegion] = useState(regionOptions[0]);
    const [context, setContext] = useState();
    const [formError, setFormError] = useState(false);

    const application = useApplication();
    const style = styles();

    const updateField = (setField) => (event) => {
        setField(event.target.value);
    };

    const updateSelectField = (setField) => (selectedValue) => {
        setField(selectedValue);
    };

    const onClick = () => {
        setGeneratingContentPlanning(true);
        const yearlyContentPlanningParameters = new YearlyContentPlanningParameters(
          subject,
          grade,
          region,
          context,
        );
        application
            .apiClient()
            .generateYearlyContentPlanning(yearlyContentPlanningParameters)
            .then((response) => {
                setFormError(false);
                onYearlyContentPlanningChange(response.yearlyContentPlanning());
                setGeneratingContentPlanning(false);
            })
            .catch(() => {
                setFormError(true);
                onYearlyContentPlanningChange();
                setGeneratingContentPlanning(false);
            });
    };

    const isSubmitButtonEnabled =
        subject &&
        grade &&
        region &&
        !isGeneratingContentPlanning;

    const renderError = () => {
        return formError ? (
            <Alert severity="error" sx={{marginTop: "15px"}}>
                Ocurrió un error al generar la respuesta.
            </Alert>
        ) : undefined;
    };

    return (
        <FramedContainer>
            <Box sx={style.mainContainer}>
                <Box sx={style.formContainer} component="form">
                    <Box sx={style.formRowContainer}>
                        <Select
                            id="region"
                            label="Región"
                            placeholder="Seleccioná una región"
                            value={region}
                            onChange={updateSelectField(setRegion)}
                            options={regionOptions}
                            required
                        />
                        <Select
                            id="grade"
                            label="Grado"
                            placeholder="Seleccioná un grado"
                            value={grade}
                            onChange={updateSelectField(setGrade)}
                            options={gradeOptions}
                            required
                        />
                    </Box>

                    <Box sx={style.formRowContainer}>
                        <Select
                            id="subject"
                            label="Materia"
                            placeholder="Seleccioná una materia"
                            value={subject}
                            onChange={updateSelectField(setSubject)}
                            options={subjectOptions}
                            required
                        />
                    </Box>
                    <Box sx={style.formRowContainer}>
                        <TextArea
                            id="instruction"
                            label="Contexto"
                            value={context}
                            onChange={updateField(setContext)}
                            placeholder="Tengo 25 estudiantes. Quisiera una planificación del cuento Caperucita Roja para alfabetizar. La mayoría lee y escribe su nombre propio, pero no pueden escribir con autonomía. Quiero enseñarles a leer y escribir."
                            fieldInfo={
                                "Indica que esperas de la planificación y cualquier información adicional que consideres relevante sobre el tema o el curso."
                            }
                        />
                    </Box>
                </Box>

                <Box>
                    <FilledTextButton
                        onClick={onClick}
                        text={!isGeneratingContentPlanning ? "Generar" : "Generando..."}
                        disabled={!isSubmitButtonEnabled}
                    />

                    {renderError()}
                </Box>
            </Box>
        </FramedContainer>
    );
}

const styles = () => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
    },
    formRowContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: "10px",
    },
})