import {Box, Toolbar, useTheme} from "@mui/material";
import React from "react";

function ContentArea({ children }) {
  const theme = useTheme();

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflowY: "hidden",
        width: "100%",
        backgroundColor: theme.palette.grey.main,
      }}
    >
      <Toolbar />
      {children}
    </Box>
  );
}

export default ContentArea;
