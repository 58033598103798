import Button from "@mui/material/Button";
import {Tooltip} from "@mui/material";

export default function FilledButton({
  children,
  onClick,
  label,
  disabled = false,
  extraStyles = undefined,
}) {
  const style = styles();
  extraStyles = extraStyles || {};

  return (
    <Tooltip title={label}>
      <Button
        color="primary"
        aria-label={label}
        variant="contained"
        onClick={onClick}
        disabled={disabled}
        sx={{ ...style.mainContainer, ...extraStyles }}
      >
        {children}
      </Button>
    </Tooltip>
  );
}

const styles = () => ({
  mainContainer: {
    width: "fit-content",
    minWidth: "fit-content",
    padding: "4px 8px",
  },
});
