export const fieldStyles = (theme) => ({
  fieldContainer: {
    width: "100%",
    borderRadius: '0.6rem',
    background: theme.palette.grey.main,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey.light,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.grey.light,
    },
  },
});
