export default class YearlyContentPlanningParameters {
    constructor(subject, grade, region, context) {
        this._subject = subject;
        this._grade = grade;
        this._region = region;
        this._context = context;
    }

    context() {
        return this._context;
    }

    region() {
        return this._region;
    }

    grade() {
        return this._grade;
    }

    subject() {
        return this._subject;
    }
}
