import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import FilledTextButton from "../../buttons/FilledTextButton";
import IconButton from "../../buttons/IconButton";
import OutlinedTextButton from "../../buttons/OutlinedTextButton";

export default function ValidateActionButtonDialog({
  icon,
  iconColor,
  iconHoverTitle,
  onConfirm,
  acceptLabel,
  title,
  extraButtonStyles = {},
  subText,
}) {
  const [open, setOpen] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleAccept = (e) => {
    e.preventDefault();
    onConfirm();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        icon={icon}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        extraStyles={extraButtonStyles}
        iconColor={iconColor}
        title={iconHoverTitle}
      />
      <Dialog
        open={open}
        sx={{
          '.MuiPaper-root': {
            padding: 2,
          },
        }}
        bodyStyle={{ zIndex: 1000 }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedTextButton text="Cancelar" onClick={handleClose} />
          <FilledTextButton
            text={acceptLabel}
            onClick={handleAccept}
            autoFocus
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
