import FilledButton from "./FilledButton";
import * as React from "react";

export default function FilledIconButton({ icon, onClick, label, disabled = false, iconSize=undefined }) {
  const style = styles(iconSize || '0.9em');
  const Icon = icon;

  return (
    <FilledButton
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      label={label}
    >
      <Icon sx={style.icon} />
    </FilledButton>
  );
}

const styles = (iconSize) => ({
  icon: {
    color: "white",
    width: iconSize,
    height: iconSize
  }
});
