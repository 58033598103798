import {AppBar, Toolbar, Typography, useTheme} from "@mui/material";
import AppVersion from "./AppVersion";
import React from "react";
import { useApplication } from "../providers/applicationProvider";
import { observer } from 'mobx-react';

function EducationAppBar() {
  const application = useApplication();
  const theme = useTheme();
  const style = styles(theme);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background:'white'}}
    >
      <Toolbar sx={style.toolbarContainer}>
        <Typography variant="h6" noWrap component="div" sx={style.title}>
          Educación Argentina
        </Typography>
        <AppVersion version={application.currentVersion} />
      </Toolbar>
    </AppBar>
  );
}

export default observer(EducationAppBar);

const styles = (theme) => ({
  toolbarContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  }
});
