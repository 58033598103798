export default class GeneratedYearlyContentPlanning {
    constructor(
        id,
        title,
        content,
        subject,
        grade,
        region,
        context,
        publicId
    ) {
        this._id = id;
        this._content = content;
        this._title = title;
        this._subject = subject;
        this._grade = grade;
        this._region = region;
        this._context = context;
        this._publicId = publicId;
    }

    id() {
        return this._id;
    }

    content() {
        return this._content;
    }

    title() {
        return this._title;
    }

    subject() {
        return this._subject;
    }

    grade() {
        return this._grade;
    }

    region() {
        return this._region;
    }

    context() {
        return this._context;
    }

    publicId() {
        return this._publicId;
    }

    hasContextFiles() {
        return false;
    }

}
