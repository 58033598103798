import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Alert, Link, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import successToast from "../components/toasts/SuccessToast";
import errorToast from "../components/toasts/ErrorToast";
import DrawerPanel from "../components/panels/DrawerPanel";
import TitleWithActions from "../components/TitleWithActions";
import LoadableContent from "../components/LoadableContent";
import { useApplication } from "../providers/applicationProvider";
import FilledTextButton from "../components/buttons/FilledTextButton";
import FilledIconTextButton from "../components/buttons/FilledIconTextButton";
import ValidateActionButtonDialog from "../components/modals/common/ValidateActionButtonDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import FramedContainer from "../components/framed-container/FramedContainer";

function ContextDetail() {
  const [contextFiles, setContextFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [isUploadingFile, setFileUploading] = useState(false);
  const [isFetchingContextFiles, setFetchingContextFiles] = useState(true);

  const application = useApplication();
  const apiClient = application.apiClient();

  const navigate = useNavigate();
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const getContextFiles = () => {
    setFetchingContextFiles(true);
    apiClient
      .listContextFiles()
      .then(({ content }) => {
        setFetchingContextFiles(false);
        setContextFiles(content);
      })
      .catch(() => {
        setFetchingContextFiles(false);
        setContextFiles([]);
      });
  };

  useEffect(() => {
    getContextFiles();
  }, [navigate]);

  const style = styles();

  // TODO: Think how can we manage this style out of here and reuse our FilledButton so as to not have repeated code
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const renderFetchingError = () => {
    return (
      <Alert severity="error" sx={{ marginTop: "15px" }}>
        No pudimos obtener los documentos.
      </Alert>
    );
  };

  const renderFileList = () => {
    return (
      <>
        {contextFiles.map((file, index) => (
          <Box sx={style.listItemContainer}>
            <Link
              href={file.url}
              color="primary"
              underline="hover"
              target={"_blank"}
            >
              {file.name}
            </Link>
            <ValidateActionButtonDialog
              icon={DeleteIcon}
              iconColor={"#8787e9"}
              iconHoverTitle={"Eliminar documento"}
              acceptLabel={"Eliminar"}
              onConfirm={() => handleFileDeleted(file.name)}
              title={"Estás a punto de eliminar este documento."}
              subText={"¿Estás seguro de que deseas eliminarlo?"}
            />
          </Box>
        ))}
      </>
    );
  };

  const handleFileSubmission = async (event) => {
    event.preventDefault();
    if (!file) {
      errorToast("Seleccione un archivo para subir al sistema");
      return;
    }

    setFileUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    apiClient.addFilesToContext(formData).then((response) => {
      setFileUploading(false);
      if (response.error) {
        errorToast("El archivo no pudo ser cargado");
      } else {
        getContextFiles();
        successToast("El archivo se cargó exitosamente");
      }
    });
  };

  const handleFileDeleted = async (fileName) => {
    apiClient.removeFileFromContext(fileName).then((response) => {
      if (response.error) {
        errorToast("El archivo no pudo ser eliminado correctamente.");
      } else {
        getContextFiles();
        successToast("El archivo se eliminó exitosamente");
      }
    });
  };

  const handleButtonClick = () => {
    document.getElementById("file-input").click();
  };

  const isFileUploadDisabled = () => {
    return !file || isUploadingFile;
  };

  return (
    <DrawerPanel>
      <TitleWithActions title="Contexto">
        <FilledIconTextButton
          text={"Seleccionar archivo"}
          onClick={handleButtonClick}
          icon={CloudUploadIcon}
        />

        <VisuallyHiddenInput
          id="file-input"
          type="file"
          onChange={handleFileChange}
          accept=".pdf"
        />

        <FilledTextButton
          onClick={handleFileSubmission}
          disabled={isFileUploadDisabled()}
          text={isUploadingFile ? "Cargando archivo..." : "Cargar archivo"}
        />
      </TitleWithActions>

      <FramedContainer>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Documentación utilizada como contexto para generar las respuestas.
        </Typography>

        <Box sx={style.listContainer}>
          <LoadableContent isLoading={isFetchingContextFiles}>
            {contextFiles.length === 0
              ? renderFetchingError()
              : renderFileList()}
          </LoadableContent>
        </Box>
      </FramedContainer>
    </DrawerPanel>
  );
}

export default ContextDetail;

const styles = () => ({
  listContainer: {
    flex: "1",
    overflowY: "scroll",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "15px",
  },
  listItemContainer: {
    border: "#313131 solid 2px",
    padding: "10px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
