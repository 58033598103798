import React, {useState} from "react";
import DrawerPanel from "../components/panels/DrawerPanel";
import TitleWithActions from "../components/TitleWithActions";
import {Box} from "@mui/material";
import YearlyContentPlanningForm from "../components/forms/YearlyContentPlanningForm";
import GeneratedYearlyContentPlanning from "../components/generated-content-planning/GeneratedYearlyContentPlanning";

// TODO: Remove repeated code. This is very similar to CreateContentPlanning
function CreateYearlyContentPlanning() {
    const [generatedYearlyContentPlanning, setGeneratedYearlyContentPlanning] = useState();

    const style = styles();

    return (
        <DrawerPanel>
            <TitleWithActions title="Planificación Anual"/>
            <Box sx={style.mainContainer}>
                <YearlyContentPlanningForm
                    onYearlyContentPlanningChange={setGeneratedYearlyContentPlanning}
                />
                <GeneratedYearlyContentPlanning
                    yearlyContentPlanning={generatedYearlyContentPlanning}
                    onYearlyContentPlanningChange={setGeneratedYearlyContentPlanning}
                />
            </Box>
        </DrawerPanel>
    );
}

export default CreateYearlyContentPlanning;

const styles = () => ({
    mainContainer: {
        flex: "1",
        overflowY: "scroll",
        paddingBottom: "30px",
    },
});
