import { Box, Divider, Typography } from "@mui/material";
import PdfExportableContainer from "./PdfExportableContainer";
import TitleWithActions from "./TitleWithActions";
import ParameterDetail from "./content-planning-history/ParameterDetail";
import GeneratedContentPlanning from "./generated-content-planning/GeneratedContentPlanning";
import GeneratedEvaluationsDetail from "./content-planning-history/GeneratedEvaluationsDetail";
import GeneratedActivitiesDetail from "./content-planning-history/GeneratedActivitiesDetail";
import GeneratedFeedbackDetail from "./content-planning-history/GeneratedFeedbackDetail";
import React from "react";
import ContentPlanningVersionTag from "./ContentPlanningVersionTag";

export default function SeeContentPlanningAndPrompt({ contentPlanning, editable }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <PdfExportableContainer
        buttonTitle="Descargar PDF"
        fileName={`planificacion-${contentPlanning.title()}.pdf`}
      >
        <TitleWithActions
          useMarkdown={true}
          title={contentPlanning.title() || contentPlanning.subject()}
        >
          <ContentPlanningVersionTag version={contentPlanning.appVersion()} />
        </TitleWithActions>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Generada el: {contentPlanning.creationDate()}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Parámetros:
        </Typography>
        <ParameterDetail
          title="Región"
          description={contentPlanning.region()}
        />
        <ParameterDetail title="Grado" description={contentPlanning.grade()} />
        <ParameterDetail title="Tema" description={contentPlanning.topic()} />
        <ParameterDetail
          title="Extensión"
          description={contentPlanning.timeUnit()}
        />
        <ParameterDetail
          title="Instrucción"
          description={contentPlanning.instruction()}
        />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Contenido generado:
        </Typography>
        <GeneratedContentPlanning contentPlanning={contentPlanning} readOnly editable={editable}/>
        <GeneratedEvaluationsDetail contentPlanning={contentPlanning} />
        <GeneratedActivitiesDetail contentPlanning={contentPlanning} />
        <GeneratedFeedbackDetail contentPlanning={contentPlanning} />
      </PdfExportableContainer>
    </Box>
  );
}