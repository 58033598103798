import GeneratedYearlyContentPlanningAdapter from "../adapters/GeneratedYearlyContentPlanningAdapter";

export default class GeneratedYearlyContentPlanningResponse {
    constructor(jsonResponse) {
        this._jsonResponse = jsonResponse;
    }

    yearlyContentPlanning() {
        return new GeneratedYearlyContentPlanningAdapter(this._jsonResponse).adapt();
    }
}
