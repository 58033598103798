import { MenuItem, Select as MuiSelect, useTheme } from "@mui/material";
import { EducationInputLabel } from "./common/EducationInputLabel";
import FieldContainer from "./common/FieldContainer";
import { fieldStyles } from "./common/fieldStyles";

function Select({
  id,
  label,
  placeholder,
  value,
  options,
  onChange,
  required = false,
  fieldInfo,
}) {
  const theme = useTheme();
  const baseStyle = fieldStyles(theme);
  return (
    <FieldContainer>
      <EducationInputLabel
        fieldInfo={fieldInfo}
        label={label}
        id={id}
        required={required}
      />

      <MuiSelect
        displayEmpty
        labelId={id}
        id={`${label}-options`}
        value={value}
        sx={baseStyle.fieldContainer}
        fullWidth
        variant="outlined"
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            value={option}
            key={option}
            onClick={() => onChange(option)}
          >
            {option}
          </MenuItem>
        ))}
      </MuiSelect>
    </FieldContainer>
  );
}

export default Select;
