import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApplication } from "../providers/applicationProvider";
import DrawerPanel from "../components/panels/DrawerPanel";
import TitleWithActions from "../components/TitleWithActions";
import LoadableContent from "../components/LoadableContent";
import SeeContentPlanningAndPrompt from "../components/SeeContentPlanningAndPrompt";
import FramedContainer from "../components/framed-container/FramedContainer";

function ContentPlanningHistory() {
  const [selectedContentPlanning, setSelectedContentPlanning] =
    useState(undefined);
  const [isFetchingHistory, setFetchingHistory] = useState(true);
  const [contentPlanningHistory, setContentPlanningHistory] = useState([]);

  const application = useApplication();
  const navigate = useNavigate();
  const apiClient = application.apiClient();
  const style = styles();

  useEffect(() => {
    const getContentPlanningHistory = () => {
      setFetchingHistory(true);
      apiClient
        .contentPlanningHistory()
        .then((response) => {
          setFetchingHistory(false);
          setContentPlanningHistory(response.contentPlannings());
        })
        .catch(() => {
          setFetchingHistory(false);
          setContentPlanningHistory([]);
        });
    };

    getContentPlanningHistory();
  }, [navigate, apiClient]);

  const hasSelectedContentPlanning = () => {
    return selectedContentPlanning !== undefined;
  };

  const selectedContentPlanningId = () => {
    return hasSelectedContentPlanning()
      ? selectedContentPlanning.id()
      : undefined;
  };

  const renderContentPlanningList = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <List
          sx={{
            width: "300px",
            flexShrink: 0,
            borderRight: 1,
            borderColor: "divider",
            overflowY: "scroll",
          }}
        >
          {contentPlanningHistory.map((contentPlanning) => {
            const id = contentPlanning.id();
            const title = contentPlanning.subject();
            const publicId = contentPlanning.publicId().toUpperCase();
            const subTitle = contentPlanning.creationDate();
            return (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  selected={selectedContentPlanningId() === id}
                  onClick={() => setSelectedContentPlanning(contentPlanning)}
                >
                  {publicId ? (
                    <ListItemText
                      primary={`${publicId} - ${title}`}
                      secondary={subTitle}
                    />
                  ) : (
                    <ListItemText primary={title} secondary={subTitle} />
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const renderSelectedContentPlanning = () => {
    if (hasSelectedContentPlanning()) {
      return (
        <Box sx={{ overflowY: "scroll" }}>
          <SeeContentPlanningAndPrompt
            contentPlanning={selectedContentPlanning}
            editable
          />
        </Box>
      );
    }
  };

  return (
    <DrawerPanel>
      <TitleWithActions title="Historial" />

      <LoadableContent isLoading={isFetchingHistory}>
        <FramedContainer>
          <Box sx={style.containerBox}>
            {renderContentPlanningList()}
            {renderSelectedContentPlanning()}
          </Box>
        </FramedContainer>
      </LoadableContent>
    </DrawerPanel>
  );
}

export default ContentPlanningHistory;

const styles = () => ({
  containerBox: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 300px)",
  },
});
