import {Box} from "@mui/material";

export default function FieldContainer({children}) {
  return (
    <Box
      width="100%"
      sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
    >
      {children}
    </Box>
  );
}