import InfoIcon from "../../InfoIcon";
import { Box, InputLabel, useTheme } from "@mui/material";

export function EducationInputLabel({ label, fieldInfo, id, required }) {
  const theme = useTheme();
  const style = styles(theme);

  const renderInfoIconWithTooltip = () => {
    if (fieldInfo) {
      return <InfoIcon details={fieldInfo} iconStyle={{ padding: "0px" }} />;
    }
  };

  return (
    <Box sx={style.mainContainer}>
      <InputLabel id={id} sx={style.label} required={required}>
        {label}
      </InputLabel>
      {renderInfoIconWithTooltip()}
    </Box>
  );
}

const styles = (theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  label: {
    fontWeight: "600",
    color: theme.palette.grey.dark,
  },
});
