import { createTheme } from "@mui/material";

const lightTheme = createTheme({
  typography: {
    fontFamily: ["NunitoSans", "Roboto", "Arial", "sans-serif"].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#18A1FF",
      light: "#64afed",
      dark: "#1363a4",
      contrastText: "#ffffff",
    },
    grey: {
      main: "#F5F6FA",
      light: "#D5D5D5",
      dark: "#2E2E2E",
    },
    violet: {
      main: "#838cf1",
      light: "#c8ccf9",
      dark: "#625bec",
      contrastText: "#020b12",
    },
  },
  text: {
    primary: "#020b12",
    secondary: "white",
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#2E2E2E",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

export default lightTheme;
