import React, { useState } from "react";
import ContentPlanningForm from "../components/forms/ContentPlanningForm";
import GeneratedContentPlanning from "../components/generated-content-planning/GeneratedContentPlanning";
import DrawerPanel from "../components/panels/DrawerPanel";
import TitleWithActions from "../components/TitleWithActions";
import { Box } from "@mui/material";
import GeneratedContentPlanningExpandingOptions from "../components/generated-content-planning/GeneratedContentPlanningExpandingOptions";

function CreateContentPlanning() {
  const [generatedContentPlanning, setGeneratedContentPlanning] = useState();

  const style = styles();

  return (
    <DrawerPanel>
      <TitleWithActions title="Planificación" />
      <Box sx={style.mainContainer}>
        <ContentPlanningForm
          onContentPlanningChange={setGeneratedContentPlanning}
        />
        <GeneratedContentPlanning
          contentPlanning={generatedContentPlanning}
          onContentPlanningChange={setGeneratedContentPlanning}
          editable
        />
        <GeneratedContentPlanningExpandingOptions
          generatedContentPlanning={generatedContentPlanning}
        />
      </Box>
    </DrawerPanel>
  );
}

export default CreateContentPlanning;

const styles = () => ({
  mainContainer: {
    flex: "1",
    overflowY: "scroll",
    width: "100%",
    padding: '1px',
    paddingBottom: "30px"
  },
});
