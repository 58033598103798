import { Box, Typography } from "@mui/material";
import * as React from "react";
import Markdown from "./Markdown";

function TitleWithActions({ title, children, useMarkdown = false }) {
  const style = styles();

  return (
    <Box sx={style.mainContainer}>
      {useMarkdown ? (
        <Markdown content={`##${title}`}></Markdown>
      ) : (
        <Typography sx={{ display: "inline-block", fontWeight: 'bold' }} variant="h5">
          {title}
        </Typography>
      )}

      <Box sx={{ display: "inline-block", float: "right" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default TitleWithActions;

const styles = () => ({
  mainContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
  },
});
