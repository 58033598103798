import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApplication } from "../providers/applicationProvider";
import SeeContentPlanningAndPrompt from "../components/SeeContentPlanningAndPrompt";
import LoadableContent from "../components/LoadableContent";
import { Box } from "@mui/material";

export default function SeeContentPlanning() {
  const publicId = useParams().publicId;
  const application = useApplication();
  const [contentPlanning, setContentPlanning] = useState();

  const style = styles();

  useEffect(() => {
    application
      .apiClient()
      .getContentPlanningByPublicId(publicId)
      .then((contentPlanning) => {
        setContentPlanning(contentPlanning);
      });
  }, []);

  return (
    <Box sx={style.mainContainer}>
      <LoadableContent isLoading={!contentPlanning}>
        <Box style={style.contentPlanningContainer}>
          <SeeContentPlanningAndPrompt contentPlanning={contentPlanning}/>
        </Box>
      </LoadableContent>
    </Box>
  );
}

const styles = () => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  contentPlanningContainer: {
    marginTop: "30px",
    width: "80%",
  },
});
